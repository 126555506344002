@import url(./spectre.min.css);

$color-primary: #009688;

// Color overrides
a, a.active, a:active, a:focus, a:hover, a:visited {
  color: #009688;
  text-decoration: underline;
}
a.btn:hover {
  background-color: #d7f2ef;
  border-color: #fff;
}
.btn.btn-outline, a.btn.btn-outline:hover {
  color: #009688;
  border-color: #009688;
}

.navbar {
  padding: 1rem 0.5rem;
  background-color: #009688;

  a, a.btn {
    color: #fff;
  }

  .btn-outline {
    background: transparent;
    border-color: #fff;
    border-radius: 0;
  }
}
.navbar .navbar-brand {
  color: #fff;
  font-size: 1.1rem;
}

.hero {
  position: relative;
  overflow: hidden;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.hero:before {
  content: "";
  position: absolute;
  width: 500%;
  height: 500%;
  top: -200%;
  left: -200%;
  z-index: -1;
  background-color: #f7f8f9;
  background: #f7f8f9 url(../img/bg-icons.png) 0 0 repeat;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  opacity: 0.9;
}

// Features on homepage
.feature {
  clear: both;
  margin: 4rem 0;
  display: flex;
  align-items: center;
  align-content: space-between;

  .feature-graphic {
    flex-grow: 1;
    max-width: 320px;
  }
  .feature-text {
    flex-grow: 2;
    padding: 1rem;
    color: #666;
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
}
// Responsive features...
@media only screen and (max-width: 600px) {
  .feature {
    display: block;
  }
}
.feature-img {
  max-width: 100%;
  max-height: 100%;
  padding: 1rem;
}

.pricing {
  h3 span {
    color: $color-primary;
  }
}
